import { BrowserRouter, Route, Routes } from "react-router-dom";
import { OrangePage } from "./app/components";

import "semantic-ui-css/semantic.min.css";
import "./App.css";
import { Segment } from "semantic-ui-react";
import { LoginPage } from "./app/components/Login";
import { TopMenu } from "./app/components/Menu/topMenu";
import { isConnected } from "./app/services/localStorage";

function App() {
  return (
    <div className={"app-container"} id={"app-container"}>
      <TopMenu />
      <Segment className={"app-body " + (isConnected() ? " egg" : "")}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<OrangePage />} />
            <Route path="/orange" element={<OrangePage />} />
            <Route path="/login" element={<LoginPage />} />
          </Routes>
        </BrowserRouter>
      </Segment>
    </div>
  );
}

export default App;
