import { IOrangeDataFilter } from "../components/Orange/types";
import { get } from "./apiService";

export const getData = async function (filter?: IOrangeDataFilter) {
  return await get("orange", filter);
};

const orangeService = {
  getData,
};
export default orangeService;
