import React from "react";
import { Icon, Image, Menu } from "semantic-ui-react";
import { menuItems } from "../../../config/constants";
import { setActive, getActive } from "./topMenuSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { isConnected, logout } from "../../services/localStorage";

export const TopMenu = () => {
  const dispatch = useAppDispatch();
  const disconnect = () => logout(true);

  const activeItem = useAppSelector(getActive);
  const setActiveItem = (name: string) => dispatch(setActive(name));

  return isConnected() ? (
    <Menu pointing secondary style={{ margin: 0 }}>
      <Image src="/static/logo-carre.png" size="mini" avatar spaced />

      <Menu.Item
        name={menuItems.orange}
        active={activeItem === menuItems.orange}
        onClick={(e: any, { name }: any) => setActiveItem(name)}
      >
        <Icon name="users" />
        {menuItems.orange}
      </Menu.Item>
      <Menu.Menu position="right">
        <Menu.Item
          name="Se déconnecter"
          active={activeItem === "Se déconnecter"}
          onClick={disconnect}
        >
          <Icon name="sign-out" />
          Se déconnecter
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  ) : null;
};
