import { configureStore } from "@reduxjs/toolkit";
import orangeDataReducer from "./components/Orange/orangeSlice";
import { menuReducer } from "./components/Menu/topMenuSlice";
import { authenticationReducer } from "./components/Login/loginSlice";

export const store = configureStore({
  reducer: {
    orangeData: orangeDataReducer,
    menu: menuReducer,
    authentication: authenticationReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
