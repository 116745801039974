const env = {
  development: {
    BASE_URL: "http://localhost:5000/",
  },
  production: {
    BASE_URL: "https://api.data-egg.fr/",
  },
  int: {
    BASE_URL: "https://api.data-egg.fr/",
  },
  test: {
    BASE_URL: "http://localhost:3334/",
  },
};

export const env_var: any = env[process.env.NODE_ENV as keyof typeof env];
