import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  FormInput,
  Grid,
  GridColumn,
  Header,
  Icon,
  Message,
  Popup,
} from "semantic-ui-react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchData, getFilter, getOrangeData, isLoading } from "./orangeSlice";
import { IOrangeDataFilter } from "./types";
import "./filter.css";

export const OrangeFilterComponent = (props: any) => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector(isLoading);
  const initialFilter = useAppSelector(getFilter);
  const data = useAppSelector(getOrangeData);

  const [filter, setFilter] = useState<IOrangeDataFilter>({
    firstname: "",
    lastname: "",
    ...initialFilter, // Initialise avec le filtre existant du store
  });
  const [isFilterVisible, setIsFilterVisible] = useState(true);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [name]: value,
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    dispatch(fetchData(filter));
    setIsFilterVisible(false);
  };

  if (!isFilterVisible && !data.orangeList?.data.length)
    setIsFilterVisible(true);

  const handleReset = () => {
    setFilter({
      firstname: "",
      lastname: "",
    });
    // dispatch(fetchData({ firstname: "", lastname: "" }));
  };

  const toggleFilterVisibility = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  return (
    <div className="orange-filter-container ">
      {!isFilterVisible && (
        <div className="show-filter-container">
          <Button
            basic
            onClick={toggleFilterVisibility}
            style={{ marginBottom: "10px" }}
          >
            <Icon name="filter" /> Afficher le filtre
          </Button>
        </div>
      )}

      {isFilterVisible && (
        <div>
          <Message attached header="Filtre" />
          <Form className="attached fluid segment" onSubmit={handleSubmit}>
            <FormGroup widths="equal">
              <FormInput
                fluid
                label="Nom"
                placeholder="Nom"
                name="lastname"
                value={filter.lastname}
                onChange={handleChange}
                type="text"
              />
              <FormInput
                fluid
                label="Prénom"
                placeholder="Prénom"
                name="firstname"
                value={filter.firstname}
                onChange={handleChange}
                type="text"
              />
            </FormGroup>
            <FormGroup widths="equal">
              <FormInput
                fluid
                label="Adresse"
                placeholder="Adresse"
                name="street"
                value={filter.street}
                onChange={handleChange}
                type="text"
              />
              <FormInput
                fluid
                label="Commune"
                placeholder="Commune"
                name="city"
                value={filter.city}
                onChange={handleChange}
                type="text"
              />
              <FormInput
                fluid
                label="Code postal"
                placeholder="Code postal"
                name="postalCode"
                value={filter.postalCode}
                onChange={handleChange}
                type="text"
              />
            </FormGroup>

            <div className="filter-btn-container">
              <Popup
                trigger={
                  <Button type="button" basic>
                    <Icon name="help" />
                    Notice
                  </Button>
                }
                flowing
                hoverable
              >
                <Grid centered divided columns={2} className="notice-popup">
                  <GridColumn textAlign="center">
                    <Header as="h4">Commence par</Header>
                    <p>
                      <b>^</b>texte
                    </p>
                    <br />
                    Exemple : ^dupont
                  </GridColumn>
                  <GridColumn textAlign="center">
                    <Header as="h4">Fini par</Header>
                    <p>
                      texte<b>$</b>
                    </p>
                    <br />
                    Exemple : dupont$
                  </GridColumn>
                </Grid>
              </Popup>

              <Button type="button" basic onClick={toggleFilterVisibility}>
                <Icon name="hide" /> Masquer le filtre
              </Button>
              <Button
                type="button"
                onClick={handleReset}
                basic
                icon
                labelPosition="right"
              >
                <Icon name="undo" /> Initialiser
              </Button>
              <Button
                type="submit"
                loading={loading}
                icon
                labelPosition="right"
              >
                <Icon name="search" /> Filtrer
              </Button>
            </div>
          </Form>
        </div>
      )}
    </div>
  );
};
