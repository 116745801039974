import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState, AppDispatch } from "../../store";
import {
  initialState,
  IOrangeDataFilter,
  IpaginatedOrangeData,
  IOrangeList,
} from "./types";
import orangeService from "../../services/orangeService";

const orangeDataSlice = createSlice({
  name: "orangeList",
  initialState,
  reducers: {
    start: (state, action: PayloadAction<{ filter: IOrangeDataFilter }>) => {
      state.isLoading = true;
      state.filter = action.payload.filter;
    },
    success: (
      state,
      action: PayloadAction<{ orangeList: IpaginatedOrangeData }>
    ) => {
      state.isLoading = false;
      state.orangeList = action.payload.orangeList;
    },
    error: (state) => {
      state.isLoading = false;
    },
  },
});

export const fetchData =
  (filter: IOrangeDataFilter) => async (dispatch: AppDispatch) => {
    dispatch(start({ filter }));
    try {
      const orangeList: IpaginatedOrangeData =
        await orangeService.getData(filter);
      dispatch(success({ orangeList }));
    } catch (err) {
      dispatch(error());
    }
  };

export const getOrangeData = (state: RootState): IOrangeList =>
  state.orangeData as IOrangeList;
export const getFilter = (state: RootState): IOrangeDataFilter =>
  state.orangeData.filter as IOrangeDataFilter;
export const isLoading = (state: RootState): boolean =>
  state.orangeData.isLoading as boolean;

export const { start, success, error } = orangeDataSlice.actions;
export default orangeDataSlice.reducer;
