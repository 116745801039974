import React, { FC, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { fetchData, getOrangeData, isLoading } from "./orangeSlice";
import {
  Button,
  Icon,
  Message,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Loader,
  Grid,
  Segment,
  Pagination,
} from "semantic-ui-react";
import moment from "moment-timezone";
import "./list.css";
import { removeDuplicates } from "../Common/helpers";
import {
  IOrangeDataAddress,
  IpaginatedOrangeData,
  IOrangeDataFilter,
} from "./types";

const AddressComponent: FC<{ addresses: IOrangeDataAddress[] }> = ({
  addresses,
}) => {
  return (
    <>
      {removeDuplicates(addresses).map((address, index) => (
        <Message compact className="address-message" key={index}>
          {address.streetNumber} {address.streetNumber2} {address.streetType}{" "}
          {address.street} {address.street2}
          <br />
          {address.postalCode} {address.city}
          <br />
          <div className="address-date">
            {moment(address.dtUpdate).format("DD/MM/YYYY")}
          </div>
        </Message>
      ))}
    </>
  );
};

const OrangeListComponent: FC = () => {
  const dispatch = useAppDispatch();
  const data = useAppSelector(getOrangeData);
  const loading = useAppSelector(isLoading);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    dispatch(fetchData({}));
  }, [dispatch]);

  if (loading) {
    return <Loader active inline="centered" />;
  }

  if (!data || !data.orangeList || !data.orangeList.data.length) {
    return <Message warning>Aucune donnée à afficher.</Message>;
  }

  const totalPages = Math.ceil(
    data.orangeList.total / (data.orangeList.limit || 10)
  );

  const handlePaginationChange = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    pageInfo: any
  ) => {
    setCurrentPage(pageInfo.activePage as number);
    dispatch(
      fetchData({
        ...data.filter,
        skip:
          ((pageInfo.activePage as number) - 1) *
          (data.orangeList?.limit || 10),
      })
    );
  };

  return (
    <Grid centered padded className="orange-table-container">
      <Grid.Column width={16} style={{ padding: 0 }}>
        <Segment basic compact style={{ padding: 0 }}>
          <Table striped compact fixed>
            <TableHeader>
              <Table.Row>
                <TableHeaderCell />
                <TableHeaderCell>Nom</TableHeaderCell>
                <TableHeaderCell>Prénom</TableHeaderCell>
                <TableHeaderCell>E-mail</TableHeaderCell>
                <TableHeaderCell>Téléphone</TableHeaderCell>
                <TableHeaderCell>Profession</TableHeaderCell>
                <TableHeaderCell>Adresse</TableHeaderCell>
                <TableHeaderCell>Date de M.A.J</TableHeaderCell>
                <TableHeaderCell>Date d'enreg.</TableHeaderCell>
              </Table.Row>
            </TableHeader>

            <TableBody>
              {data.orangeList.data.map((line, index) => (
                <TableRow key={line.key}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{line.lastname}</TableCell>
                  <TableCell>{line.firstname}</TableCell>
                  <TableCell>{line.email}</TableCell>
                  <TableCell>{line.phone}</TableCell>
                  <TableCell>{line.profession}</TableCell>
                  <TableCell>
                    <AddressComponent addresses={line.address} />
                  </TableCell>
                  <TableCell>
                    {moment(line.dtUpdate).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell>
                    {moment(line.dtExec).format("DD/MM/YYYY")}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Segment>

        <div className="pagination-container">
          <Pagination
            activePage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePaginationChange}
            ellipsisItem={{
              content: <Icon name="ellipsis horizontal" />,
              icon: true,
            }}
            firstItem={{
              content: <Icon name="angle double left" />,
              icon: true,
            }}
            lastItem={{
              content: <Icon name="angle double right" />,
              icon: true,
            }}
            prevItem={{ content: <Icon name="angle left" />, icon: true }}
            nextItem={{ content: <Icon name="angle right" />, icon: true }}
            boundaryRange={1} // Optionnel : ajuste les boutons de pagination visibles
            siblingRange={5} // Optionnel : ajuste les boutons de pagination visibles
            className="pagination" // Ajoutez cette classe pour le style CSS
          />
        </div>
      </Grid.Column>
    </Grid>
  );
};

export default OrangeListComponent;
