import { OrangeFilterComponent } from "./filter";
import "./orange.css";
import OrangeListComponent from "./list";

export const OrangePage = (props: any) => {
  return (
    <div className="orange-container">
      <OrangeFilterComponent />
      <OrangeListComponent />
    </div>
  );
};
