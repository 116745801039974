export const setTokens = (authRes: any) => {
  if (authRes?.access_token) {
    localStorage.setItem("token", authRes.access_token);
    localStorage.setItem("user", JSON.stringify(authRes.user));
  }
};

export const removeTokens = () => {
  localStorage.clear();
};
export const getAccessToken = () => localStorage.getItem("token");
export const getUser = () => JSON.parse(localStorage.getItem("user") || "{}");

export const logout = (reload = false) => {
  removeTokens();
  if (reload) window.location.replace("/login");
};
export const isConnected = () => {
  return !!getAccessToken();
};
