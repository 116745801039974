export interface IOrangeDataAddress {
  dtUpdate: Date;
  countryCode?: string;
  district?: string;
  postalCode?: string;
  streetNumber?: string;
  streetNumber2?: string;
  streetType?: string;
  street?: string;
  street2?: string;
  setupDistrict?: string;
  setupPostalCode?: string;
  cedex?: string;
  bp?: string;
  city?: string;
}
export interface IOrangeData {
  id: string;
  key: string; //N4 + U11
  dtUpdate: Date; //N2
  dtExec: Date; //nowé
  designation?: string; // U4
  type?: string; // U5
  address: IOrangeDataAddress[];
  lastname: string;
  firstname: string;

  activity?: string; // U6

  profession?: string; // U7

  email?: string; // U10
  phone?: string;
}

export interface IpaginatedOrangeData {
  data: IOrangeData[];
  total: number;
  skip: number;
  limit?: number;
}
export interface IOrangeDataFilter {
  firstname?: string;
  lastname?: string;
  city?: string;
  postalCode?: string;
  street?: string;
  skip?: number;
}

export interface IOrangeList {
  isLoading: boolean;
  orangeList?: IpaginatedOrangeData;

  filter: IOrangeDataFilter;
}
export const initialState: IOrangeList = {
  isLoading: false,
  orangeList: { data: [], total: 0, skip: 0, limit: 10 },
  filter: { lastname: "", firstname: "" },
};
