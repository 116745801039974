import axios from "axios";
import { env_var } from "../../config/env";
import toasts from "../../helpers/toast";
import { getAccessToken, logout } from "./localStorage";

axios.defaults.baseURL = env_var.BASE_URL;
export const getHeaderInfo = async function (headers = {}) {
  let token = getAccessToken();
  return {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...headers,
    },
  };
};

const handleResponse = async (response: any) => {
  if (!response) {
    throw new Error("Erreur, l'API est inaccessible");
  }

  if ([401, 403].includes(response.status)) {
    return logout(true);
  }

  if (response.data?.error && response.data?.message) {
    if (!Array.isArray(response.data.message))
      response.data.message = [response.data.message];
    response.data.message.forEach(toasts.error);
    throw new Error(response.data.message.join("-"));
  }

  if (response.data?.status !== "OK") {
    return response.data;
  }
  return response;
};

export const get = async function (url: any, params: any = {}) {
  let header = await getHeaderInfo();
  try {
    let resp = await axios.get(url, { ...header, params });
    return handleResponse(resp);
  } catch (err: any) {
    throw handleResponse(err.response);
  }
};

export const post = async function (
  url: string,
  body: any = {},
  headers: any = {}
) {
  let header = await getHeaderInfo(headers);
  try {
    let resp = await axios.post(env_var.BASE_URL + url, body, header);
    return handleResponse(resp);
  } catch (err: any) {
    return handleResponse(err.response);
  }
};
