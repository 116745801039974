import { IOrangeDataAddress } from "../Orange/types";

export const removeDuplicates = (addresses: IOrangeDataAddress[]) => {
  const seen = new Set<string>();

  return addresses.filter((address: IOrangeDataAddress) => {
    const { dtUpdate, ...addressWithoutDtUpdate } = address;
    const key = JSON.stringify(addressWithoutDtUpdate);

    if (seen.has(key)) {
      return false;
    } else {
      seen.add(key);
      return true;
    }
  });
};
