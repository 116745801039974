import { post } from "./apiService";
import { getAccessToken } from "./localStorage";

export interface AuthPayload {
  email: string;
  password: string;
  view?: string;
}
export interface TokenPayload {
  user: string;
  token: string;
}

export const authenticate = async (data: AuthPayload) => {
  return post("auth/signin", {
    email: data.email,
    password: data.password,
  });
};

export const resetPassword = async (data: AuthPayload) => {
  return post("auth/resetPassword", {
    email: data.email,
    view: data.view,
  });
};

export const checkUserToken = async (data: TokenPayload) => {
  return post("auth/checkUserToken", data);
};
export const changePassword = async (data: TokenPayload) => {
  return post("auth/changePassword", data);
};
export const isAuthenticated = (): boolean => {
  return getAccessToken() ? true : false;
};
