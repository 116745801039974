import React, { ReactNode, useState } from "react";
import { authenticateUser, isLoading, getError, getInfo } from "./loginSlice";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Message,
  Segment,
} from "semantic-ui-react";
import { isAuthenticated } from "../../services/authenticationService";

export function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const dispatch = useAppDispatch();

  const loading = useAppSelector(isLoading);
  const error = useAppSelector(getError);
  let info = useAppSelector(getInfo);

  if (isAuthenticated()) {
    window.location.reload();
  }

  const login = (values: any) => {
    dispatch(authenticateUser({ email, password }));
  };

  const getCurrentView = (): ReactNode => {
    return <div>Authentification</div>;
  };
  const getCurrentFooter = () => {
    return (
      <div className="login-action-links">
        <Button
          primary
          fluid
          size="large"
          loading={loading}
          onClick={login}
          icon
          labelPosition="right"
        >
          Connexion
          <Icon name="sign in" />
        </Button>
      </div>
    );
  };
  return (
    <Grid textAlign="center" style={{ height: "100vh" }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" className="egg-violet" textAlign="center">
          <Image src="/static/logo-carre.png" /> {getCurrentView()}
        </Header>
        <Segment>
          <Form size="large">
            <Form.Input
              fluid
              icon="mail"
              iconPosition="left"
              placeholder="E-mail"
              value={email}
              onChange={(e, { value }) => setEmail(value)}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Mot de passe"
              type="password"
              value={password}
              onChange={(e, { value }) => setPassword(value)}
            />

            {getCurrentFooter()}

            {error || info ? (
              <Message
                size="mini"
                error={!info}
                compact
                icon={info ? "info circle" : "warning circle"}
                content={error || info}
              />
            ) : null}
          </Form>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
